import axios from 'axios'
import vue from 'vue'
const request = axios.create({
    timeout: 1000*60*60,
})

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.interceptors.request.use(function (config) {
    return config
}, function (error) {
    return Promise.reject(error)
})
request.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response.status === 401) {
        location.hash = '/login'
    }
    if (error.response.status === 403) {
        location.hash = '/403'
    }
    if (error.response.status === 500) {
        location.hash = '/500'
    }
    return Promise.reject(error);
});
export default request